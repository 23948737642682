.nav-logo {
    border-radius: 5px;
    transform: translateY(1.5px);
    margin-right: 10px !important;
}

.active {
    background-color: var(--highlight-bg);
    border-radius: 5px;
}

.footer {
    width: 100%;
    padding: 2vh;
    background-color: rgb(30, 30, 30);
    color: rgb(79, 81, 96);
}

.footer * {
    justify-content: space-evenly;
}

.footer i {
    font-size: 3vh;
    margin-left: 3vh;
    margin-right: 3vh;
}

.footer a {
    text-decoration: none;
    color: inherit;
}

.footer .link {
    cursor: pointer;
}

.footer .link:hover {
    color: var(--primary-color);
}

/* .container {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr;
}

.container i {
    font-size: 10rem;
    color: var(--primary-color);
    transition: 0.5s;
}

.container .item {
    color: var(--highlight-text-color);
    border-radius: var(--border-radius);
    padding: 3rem;
    cursor: pointer;
    transition: 0.5s;
}

.container i:hover {
    color: var(--primary-200) !important;
}

.container .item h1,a {
    color: var(--highlight-text-color);
    text-decoration: none;
}*/

.seperator {
    color: rgb(79, 81, 96);
}

span.primary {
    color: var(--primary-color);
}


span.highlight {
    color: rgb(140, 93, 246);
}

.contact {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr;
    width: 80%;
    text-align: start;
    margin: auto;
}

.contact h1 {
    font-size: 7vh;
}

.contact h2 {
    color: rgb(79, 81, 96);
    font-weight: 600;
}

/* .contact label {
    color: rgb(79, 81, 96);
} */

.contact-form {
    width: 70%;
    padding: 2vh;
    padding-top: 0;
    margin: auto;
    border-radius: 10px;
    /* border: 1px solid rgb(140, 93, 246); */
    background: rgb(2,0,36);
    background: linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(64,40,173,1) 92%);
    /* background-color: rgb(16,13,35); */
    font-family: 'Secular One', sans-serif;
    overflow: hidden
}

@import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');

.contact-form .p-inputtext {
    width: 90%;
    background-color: rgb(18, 18, 18);
    border: none;
    outline: none;
    padding: 15px;
    border-radius: 8px;
    margin: 1.2vh;
    color: var(--primary-color);
    font-size: 1.9vh;
    font-family: 'Secular One', sans-serif;
}

.contact-icons {
    display: grid;
    /* grid-template-columns: 1fr 1fr; */
    gap: 10vh;
    margin: auto;
}
.contact-icons i {
    font-size: 10vh;
    color: var(--primary-color);
    cursor: pointer;
    transition: 1s;
}


.contact-icons i:hover {
    color: rgb(140, 93, 246);
    transform: scale(1.4) rotate(360deg);
    /* transform: rotate(360deg); */
}


.contact-icons-mobile {
    display: none;
    visibility: hidden;
}

.contact-icons-mobile i {
    font-size: 10vh;
    color: var(--primary-color);
    cursor: pointer;
    transition: 1s;
}

.contact-icons-mobile i:hover {
    color: rgb(140, 93, 246);
    transform: scale(1.4) rotate(360deg);
    /* transform: rotate(360deg); */
}

@media only screen and (max-width: 1450px) {
    .contact-form {
        width: 90%;
    }

    .contact {
        width: 90%;
    }
}

@media only screen and (max-width: 1000px) {
    .contact {
        display: block;
    }
    .contact-icons {
        display: none;
        visibility: hidden;
    }
    .contact-icons-mobile {
        display: block;
        visibility: visible;
    }
    .contact-form {
        width: 60%;
    }
}


@media only screen and (max-width: 800px) {
    .contact-form {
        width: 80%;
    }
}

@media only screen and (max-width: 700px) {
    .contact-form {
        width: 90%;
    }
}

