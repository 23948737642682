@import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');

.background-img {
    background-image: url('./assets/background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    filter: brightness(50%);
    scroll-behavior: smooth;
    height: 100vh;
    width: 100%;
    position: absolute !important;
    top: 0;
    z-index: -1;
}

.background-title {
    font-size: 3vh;
    padding-top: 10vh;
    /* font-family: 'Secular One', sans-serif; */
    font-family: Arial, Helvetica, sans-serif;
    color: rgb(233, 233, 233);
    /* color: rgb(50, 134, 43); */
}

.scroll-btn {
    cursor: pointer;
    color: rgb(95, 97, 112);
    font-size: 3.5vh;
}

.dynamic-text {
    transition: 0.9s;
    display: inline-block;
}

.dynamic-text.hidden {
    transform: translateY(20px);
    opacity: 0;
}

.dynamic-text.visible {
    transform: translateY(-20px);
    opacity: 0;
}

.home {
    width: 80%;
    text-align: start;
    margin: auto;
}

.home h1 {
    font-size: 7vh;
}

.home h2 {
    color: rgb(79, 81, 96);
    font-weight: 600;
}

/* .capabilties { */
    /* display: flex;  */
    /* flex-wrap: wrap; */
    /* display: flexbox; */
    /* flex: content; */
    /* display: inline-flex; */
    /* width: 100%; */
    /* overflow-x: auto; */
    /* display: inline-flex; */
/* } */

.capability {
    width: 12vw;
    display: inline-block;
    /* width: 100%; */
    /* min-width: max-content; */
    height: 30vh;
    margin: 5px;
    background-color: var(--highlight-bg);
    text-align: center;
    border-radius: 10px;
    color: var(--text-color);
    transition: 0.5s;
    cursor: pointer;
}



@media only screen and (max-width: 1300px) {
    .capability {
        display: block;
        width: 40%;
        margin: auto;
        margin-bottom: 1vh;
    }
}

@media only screen and (max-width: 800px) {
    .capability {
        width: 80%;
    }
}

.capability .logo {
    border: 1px solid var(--highlight-bg);
    border-radius: inherit;
    width: 100%;
    height: 80%;
    padding: 50px;
}

.capability .logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.capability:hover {
    transform: scale(1.1);
}


.overflow-x-auto {
    overflow-x: auto;
}

.reasons td {
    padding: 6px !important;
}

.reasons-list {
    visibility: hidden;
    display: none;
}

@media only screen and (max-width: 1850px) {
    .reasons td {
        padding: 1px !important;
    }
}


@media only screen and (max-width: 1500px) {
    .reasons {
        visibility: hidden;
        display: none;
    }

    .reasons-list {
        visibility: visible;
        display: block;
    }
}

.center {
    text-align: center;
}